<template>
  <Popup ref="popupRef" width="500px" title="留言回复" confirm-text="提交留言" @confirm="confirm">
    <el-input type="textarea" placeholder="请输入内容" maxlength="120" show-word-limit rows="3" v-model="inputValue"></el-input>
    <div class="label">添加图片</div>
    <div class="img-list">
      <div class="img-item" v-for="(item, index) in imgList" :key="index">
        <img class="icon-close" src="@/assets/images/close.png" @click="removeImage(index)">
        <LoadImage class="img" :src="item.url"></LoadImage>
      </div>
      <UploadImage :autoUpload="false" multiple @success="uploadSuccess"></UploadImage>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import LoadImage from '@/components/LoadImage'
import UploadImage from '@/components/UploadImage'
import { fileToBase64Async } from '@/utils/util.js'
import uploadImageUtil from '@/utils/uploadImageUtil.js'
import { reportMsgApi } from '@/api/report.js'
export default {
  components: {
    Popup,
    LoadImage,
    UploadImage
  },
  data() {
    return {
      inputValue: '',
      imgList: [],
      rid: ''
    }
  },
  methods: {
    open({rid}) {
      this.rid = rid
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    uploadSuccess(fileList) {
      if(fileList.length + this.imgList.length > 3) return this.$tips({message:'最多上传3张图片', type:'warning'})
      console.log(fileList)
      // for (const file of fileList) {
      //   fileToBase64Async(file).then(base64Url => {
      //     this.imgList.push({
      //       url: base64Url,
      //       file
      //     })
      //   })
      // }
      for (const item of fileList) {
        this.imgList.push({
          url: item.base64Url,
          file: item.file
        })
      }
      
    },
    removeImage(index) {
      this.imgList.splice(index, 1)
    },
    async confirm() {
      if(!this.inputValue.trim()) return this.$tips({message: '内容不能为空', type: 'warning'})
      if(this.imgList.length > 0) {
        const { uploadImageAsync } = await uploadImageUtil()
        let count = this.imgList.length
        const ossImgList = []
        for (const item of this.imgList) {
          uploadImageAsync(item.file).then(res => {
            ossImgList.push(res.ossUrl)
            count--
            if(count <= 0) this.handleConfirm(ossImgList)
          }).catch(err => {
            this.$tips({message: '上传图片失败', type: 'error'})
            count--
            if(count <= 0) this.handleConfirm(ossImgList)
          })
        }
      } else {
        this.handleConfirm()
      }
    },
    handleConfirm(ossImgList = []) {
      const formData = {
        rid: this.rid,
        msg: this.inputValue.trim(),
        pics: ossImgList.join(',')
      }
      console.log('formData', formData)
      reportMsgApi(formData).then(res => {
        console.log('留言成功', res)
        this.close()
        this.$emit('update')
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  font-size: 14px;
  margin: 24px 0 8px;
  color: #505E5C;
}
.tips {
  margin: 8px 0;
  font-size: 12px;
}
.img-list {
  display: flex;
  flex-wrap: wrap;
  .img-item {
    position: relative;
    .icon-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .img {
    width: 104px;
    height: 104px;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 8px 8px 0;
    border: 1px solid #f5f5f5;
  }
}
</style>